import { navigate } from "gatsby-link";
import {
  Box,
  Image,
  ResponsiveContext,
  TextInput,
  Button,
  Text,
  Heading,
} from "grommet";
import React, { useState } from "react";
import Layout from "../components/elements/layout";
import mainLogo from "../images/logo.png";
import { forgotPassword } from "../services/login_service";
// import service from '../services/login_service';
// import storageService from '../services/storage_service';

const ForgotPasswordPage = ({ size, logo }) => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const login = async () => {
    if (
      !username ||
      !username.includes("@") ||
      !username.includes(".") ||
      username.length < 6
    ) {
      setError("Identifiant non valid");
      return;
    }

    // if (!password || password.length < 6) {
    //   setError("Mot de passe au moin 6 caractères");
    //   return;
    // }
    setError("");
    setLoading(true);
    forgotPassword(username, password)
      .then((res) => {
        const s = res.data;
        if (s.success) {
          alert(
            "La demmande de réinitialiser votre mot de passe est bien enregistré. Un nouveau mot de passe a été envoyé à votre email."
          );
          navigate("/login");
        } else {
          alert("Identifiant non trouvé");
          setLoading(false);
        }
      })
      .catch((err) => {
        alert("Identifiant non trouvé");
        setLoading(false);
      });
  };

  const inputStyle = {
    background: "#EDEDED",
    fontSize: 16,
    borderRadius: 10,
    border: "none",
  };
  return (
    <Layout
      style={{ justifyContent: "center", alignContent: "center" }}
      hideHeader="true"
      hideFooter="true"
    >
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            justify="center"
            overflow="auto"
            direction="column"
            alignContent="center"
            alignSelf="center"
            height="100vh"
          >
            <Box
              width="medium"
              alignSelf="center"
              justify="center"
              align="center"
              alignContent="center"
            >
              <Image src={logo || mainLogo} margin="small" />
              <Heading
                level="3"
                style={{ color: "#707070" }}
                textAlign="center"
              >
                Réinitialiser votre mot de passe
              </Heading>
              <Text
                level="5"
                style={{ color: "#707070", marginBottom: 30 }}
                textAlign="center"
              >
                Pour réinitialiser votre mot de passe, saisissez l'adresse que
                vous utilisez pour vous connecter à votre compte My Trustiway.
              </Text>

              <Text
                size="small"
                style={{ color: "#dc3545" }}
                textAlign="center"
              >
                {error}
              </Text>
              <Box width="large" gap="small">
                <TextInput
                  placeholder="Email "
                  onChange={(event) => setUserName(event.target.value)}
                  style={inputStyle}
                />
                <Box width="small" alignSelf="center">
                  <Button
                    primary
                    label="Envoyer"
                    onClick={login}
                    disabled={loading}
                  />
                </Box>
                <Box width="large" align="center">
                  <Button
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <Text
                      style={{ color: "#707070", fontSize: 14 }}
                      textAlign="center"
                    >
                      Je me connecte
                    </Text>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  );
};

export default ForgotPasswordPage;
